import afore  from './staticDashboards/afore'
import aforePrev  from './staticDashboards/aforePrev'
import sucursal  from './staticDashboards/sucursal'
import callCenterI  from './staticDashboards/callCenterI'
import chatHumanoI  from './staticDashboards/chatHumanoI'
import alba  from './staticDashboards/alba'
import mobile  from './staticDashboards/mobile'
import whastappAlba  from './staticDashboards/whastappAlba'
import custodiaPresencial  from './staticDashboards/custodiaPresencial'
import cuentasInstitucionales  from './staticDashboards/cuentasInstitucionales'
import custodiaTelefonica  from './staticDashboards/custodiaTelefonica'
import general  from './staticDashboards/general'
import acompanamiento  from './staticDashboards/acompanamiento'
import estadoDeCuenta  from './staticDashboards/estadoDeCuenta'
import ventaPrestamosSOFOM  from './staticDashboards/ventaPrestamosSOFOM'
import ventaTraspasos  from './staticDashboards/ventaTraspasos'
import ventaRegistros  from './staticDashboards/ventaRegistros'
import relacionalAfore  from './staticDashboards/relacionalAfore'
import relacionalPrestamoSOFOM from './staticDashboards/relacionalPrestamoSOFOM'
import relacionalPensiones from './staticDashboards/relacionalPensiones'
import autoAfiliacion from './staticDashboards/autoAfiliacion'
import portalPrivado from './staticDashboards/portalPrivado'
import chatHumanoII from './staticDashboards/chatHumanoII'

export const profuturoStaticDashboards: Record<string, DasboardConfig> = {
  '173005401': {
    title: 'Feria de las Afores - Módulo Afore',
    description: 'Feria de las Afores - Módulo Afore',
    // @ts-ignore
    widgets: afore.widgets,
  },
  '173005402': {
    title: 'App Profuturo Móvil',
    description: 'App Profuturo Móvil',
    // @ts-ignore
    widgets: aforePrev.widgets,
  },
  '13604': {
    title: 'Sucursal',
    description: 'Sucursal',
    // @ts-ignore
    widgets: sucursal.widgets,
  },
  '10373': {
    title: 'Call Center 1',
    description: 'Call Center 1',
    // @ts-ignore
    widgets: callCenterI.widgets,
  },
  '10370': {
    title: 'Chat Humano',
    description: 'Chat Humano',
    // @ts-ignore
    widgets: chatHumanoI.widgets,
  },
  /*'10367': {
    title: 'Chat Humano II',
    description: 'Chat Humano II',
    // @ts-ignore
    widgets: chatHumanoII.widgets,
  },*/
  '10351': {
    title: 'Chat Boot Alba',
    description: 'Chat Boot Alba',
    // @ts-ignore
    widgets: alba.widgets,
  },
  '10352': {
    title: 'Profuturo Mobile',
    description: 'Profuturo Mobile',
    // @ts-ignore
    widgets: mobile.widgets,
  },
  '173215451': {
    title: 'Whatsapp Alba',
    description: 'Whatsapp Alba',
    // @ts-ignore
    widgets: whastappAlba.widgets,
  },
  '10365': {
    title: 'Custodia Presencial',
    description: 'Custodia Presencial',
    // @ts-ignore
    widgets: custodiaPresencial.widgets,
  },
  '10366': {
    title: 'Cuentas Institucionales',
    description: 'Cuentas Institucionales',
    // @ts-ignore
    widgets: cuentasInstitucionales.widgets,
  },
  '10364': {
    title: 'Custodia Telefónica',
    description: 'Custodia Telefónica',
    // @ts-ignore
    widgets: custodiaTelefonica.widgets,
  },
  '9951': {
    title: 'Acompañamiento en trámite de pensión',
    description: 'Acompañamiento en trámite de pensión',
    // @ts-ignore
    widgets: acompanamiento.widgets,
  },
  '10353': {
    title: 'Estado de cuenta',
    description: 'Estado de cuenta',
    // @ts-ignore
    widgets: estadoDeCuenta.widgets,
  },
  '10360': {
    title: 'Venta préstamos SOFOM',
    description: 'Venta préstamos SOFOM',
    // @ts-ignore
    widgets: ventaPrestamosSOFOM.widgets,
  },
  '10361': {
    title: 'Venta Traspasos',
    description: 'Venta Traspasos',
    // @ts-ignore
    widgets: ventaTraspasos.widgets,
  },
  '10362': {
    title: 'Venta Registros',
    description: 'Venta Registros',
    // @ts-ignore
    widgets: ventaRegistros.widgets,
  },
  '173004203': {
    title: 'Relacional Afore',
    description: 'Relacional Afore',
    // @ts-ignore
    widgets: relacionalAfore.widgets,
  },
  '173004205': {
    title: 'Relacional Pensiones',
    description: 'Relacional Pensiones',
    // @ts-ignore
    widgets: relacionalPensiones.widgets,
  },
  '173004204': {
    title: 'Relacional Prestamos SOFOM',
    description: 'Relacional Prestamos SOFOM',
    // @ts-ignore
    widgets: relacionalPrestamoSOFOM.widgets,
  },
 '11451':  {
     title: 'Autoafiliacion',
     description: 'Autoafiliacion',
     // @ts-ignore
     widgets: autoAfiliacion.widgets
  },
 '0': {
    title: 'Dashboard default',
    description: 'Dashboard default',
    // @ts-ignore
    widgets: general.widgets,
  },
  '173225301': {
     title: 'Dashboard default',
     description: 'Dashboard default',
     // @ts-ignore
     widgets: portalPrivado.widgets,
    
  }
  
}