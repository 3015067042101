import { getCommonWidget } from "../../dashWidgetHelper";
import { PRODUTURO_PIE_COLORS, commonWidgets, getProfuturoAtencion,
     getProfuturoNpsAcumulado, getProfuturoNpsPorMes, getProfuturoSatisfaccion, getProfuturoCustomerJourney, 
     PROFUTURO_GREEN_2,
     PROFUTURO_GREEN_1,
     PROFUTURO_RED_1} from "../commonWidgetsProfuturo";

export default {
  title: 'Acompañamiento Dashboard',
  description: 'Acompañamiento Dashboard',
  backgroundClassName: (filters: FiltersUI) => 'epsilon-hero-bg',
  widgets: (filters: FiltersUI) => [
  {
    title: 'Principales Indicadores',
    position: [0, 0],
    size: [4, 1],
     type: 'label',
     config: { color: '#B7AADC' },
  },
  getProfuturoNpsAcumulado({
    position: [0, 1],
    size: [1, 2],
    indicator: 'chat_humano_l_pregunta_15'
  }),
  getProfuturoNpsPorMes({
    position: [1, 1],
    size: [1, 2],
    indicator: 'chat_humano_l_pregunta_15',
  }),
  getProfuturoSatisfaccion({
    position: [2, 1],
    size: [1, 2],
    indicator: 'chat_humano_l_pregunta_2',
    helpText: '¿Qué tan satisfecho estás con tu interacción con el asesor de Profuturo a través de chat?'
  }),
  getProfuturoAtencion({
    position: [3, 1],
    size: [1, 2],
    indicator: 'chat_humano_l_pregunta_3',
    helpText: 'Pensando en tu interacción, ¿cómo evaluarías la atención recibida por parte del asesor Profuturo a través del chat?'
  }),
  {
    title: 'Motivadores y quiebres',
    position: [0, 3],
    size: [4, 1],
     type: 'label',
     config: { color: '#B7AADC' },
  },
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'pieGenericMedium',
    title: 'Atención: clientes insatisfechos',
    position: [0, 4],
    size: [2, 2],
    indicator: 'chat_humano_l_pregunta_4',
    helpText: ' Señalaste que la atención recibida no fue buena, ¿por qué motivos?',
    extraConfigs: {
      colors: PRODUTURO_PIE_COLORS,
      itemGap: 10,
      center: ['30%', '55%'],
    }
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'pieGenericMedium',
    title: 'Atención: clientes neutros',
    position: [2, 4],
    size: [2, 2],
    indicator: 'chat_humano_l_pregunta_5',
    helpText: ' ¿En qué podríamos mejorar? ',
    extraConfigs: {
      colors: PRODUTURO_PIE_COLORS,
      itemGap: 10,
      center: ['30%', '55%'],
    }
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'pieGenericMedium',
    title: 'Atención: clientes satisfechos',
    position: [0, 6],
    size: [2, 2],
    indicator: 'chat_humano_l_pregunta_6',
    helpText: '¿Qué fue lo que más valoraste?',
    extraConfigs: {
      colors: PRODUTURO_PIE_COLORS,
      itemGap: 10,
      center: ['30%', '55%'],
    }
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'pieGenericMediumSiNo',
    title: 'Efectividad',
    position: [2, 6],
    size: [2, 2],
    helpText: '¿Pudiste resolver todo lo que necesitabas?',
    indicator: 'chat_humano_l_pregunta_12'
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'pieGenericMedium',
    title: 'Interacción con Alba previamente',
    position: [0, 8],
    size: [2, 2],
    indicator: 'chat_humano_l_pregunta_13',
    helpText: '¿Interactuaste con Alba previamente?',
    extraConfigs: {
      colors: {
        0: PROFUTURO_GREEN_1,
        1: PROFUTURO_GREEN_2,
        2: PROFUTURO_RED_1,
      },
    }
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'wordCount',
    title: 'Consultas no resueltas desde Alba',
    helpText: '¿Nos puedes contar un poco más sobre la consulta/trámite que no pudiste resolver a través de Alba?',
    position: [2, 8],
    size: [2, 2],
    indicator: 'chat_humano_l_pregunta_14'
  }),
  getProfuturoCustomerJourney({
         id: 'cj-chatHumano',
         position: [0, 10],
         size: [3, 2],
         indicators: [  { name: 'chat_humano_l_pregunta_2', formula: 'count-number' }, //csat
                        { name: 'chat_humano_l_pregunta_3', formula: 'count-number' }, // atencion
                        { name: 'chat_humano_l_pregunta_15', formula: 'nps-10' }, //nps
         ],
         title: 'Customer Journey: Top Two Box',
         
     }),
  {
    title: 'Atributos de experiencia',
    position: [0, 12],
    size: [4, 1],
     type: 'label',
  },
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'stackedBar',
    title: 'El tiempo que llevó realizar mis consultas / trámites fue el apropiado',
    position: [0, 13],
    size: [4, 1],
    helpText: 'Pensando en tu interacción con el asesor Profuturo a través del chat, ¿qué tan de acuerdo te encuentras con las siguientes frases?',
    indicator: 'chat_humano_l_pregunta_7'
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'stackedBar',
    title: 'Me ofreció soluciones adecuadas a mis necesidades.',
    position: [0, 14],
    size: [4, 1],
    helpText: 'Pensando en tu interacción con el asesor Profuturo a través del chat, ¿qué tan de acuerdo te encuentras con las siguientes frases?',
    indicator: 'chat_humano_l_pregunta_9'
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'stackedBar',
    title: 'Me ahorró la necesidad de realizar la consulta / trámite a través de otros canales.',
    position: [0, 15],
    size: [4, 1],
    helpText: 'Pensando en tu interacción con el asesor Profuturo a través del chat, ¿qué tan de acuerdo te encuentras con las siguientes frases?',
    indicator: 'chat_humano_l_pregunta_10',
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'stackedBar',
    title: 'Me resulta un canal seguro y confiable.',
    position: [0, 16],
    size: [4, 1],
    helpText: 'Pensando en tu interacción con el asesor Profuturo a través del chat, ¿qué tan de acuerdo te encuentras con las siguientes frases?',
    indicator: 'chat_humano_l_pregunta_11'
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'textAnalysis',
    title: 'Análisis de Sentimientos',
    position: [0, 17],
    size: [4, 4],
    indicator: 'SENTIMENT'
  }),
]} as DasboardConfig
