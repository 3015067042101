import { getCommonWidget } from "../../dashWidgetHelper";
import { PRODUTURO_PIE_COLORS, commonWidgets, getProfuturoAtencion, getProfuturoFacilidad,
getProfuturoNpsAcumulado, getProfuturoNpsPorMes, getProfuturoSatisfaccion,getProfuturoNpsConsar, getProfuturoCustomerJourney, 
getNpsPorEjecutivoTable} from "../commonWidgetsProfuturo";

export default {
  title: 'Acompañamiento Dashboard',
  description: 'Acompañamiento Dashboard',
  backgroundClassName: (filters: FiltersUI) => 'epsilon-hero-bg',
  widgets: (filters: FiltersUI) => [
    {
      title: 'Principales indicadores',
      position: [0, 0],
      size: [4, 1],
      type: 'label',
      config: { color: '#B7AADC' },
    },
    getProfuturoNpsAcumulado({
      position: [0, 1],
      size: [1, 2],
      indicator: 'call_center_l_pregunta_35'
    }),
    getProfuturoNpsPorMes({
      position: [1, 1],
      size: [1, 2],
      indicator: 'call_center_l_pregunta_35',
    }),
    getProfuturoSatisfaccion({
      position: [2, 1],
      size: [1, 2],
      indicator: 'call_center_l_pregunta_2',
      helpText: '¿Qué tan satisfecho estás con la llamada que hiciste al Centro de Atención Telefónica?'
    }),
    getProfuturoFacilidad({
      position: [3, 1],
      size: [1, 2],
      indicator: 'call_center_l_pregunta_6',
      helpText: 'Pensando en tu última llamada al Centro de Atención Telefónica, ¿qué tan sencillo te resultó ponerte en contacto?'
    }),
    getProfuturoAtencion({
      position: [0, 3],
      size: [1, 2],
      indicator: 'call_center_l_pregunta_17',
      helpText: 'Ahora vamos a hablar de la atención que recibiste por parte del consultor telefónico. ¿Cómo fue tu experiencia?'
    }),
    getProfuturoNpsConsar({
      indicator: 'call_center_l_pregunta_28',
      position: [1, 3],
      size: [1, 2],
      title: 'NPS CONSAR',
      helpText: '¿Qué tan probable es que recomiendes el servicio de Call Center con amigos y/o familiares?'
    }),
    {
      title: 'Motivadores y quiebres',
      position: [0, 5],
      size: [4, 1],
      type: 'label',
      config: { color: '#B7AADC' },
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Satisfacción: clientes insatisfechos',
      position: [0, 6],
      size: [2, 2],
      indicator: 'call_center_l_pregunta_5',
      helpText: 'Señalaste que estás insatisfecho con la llamada, ¿en qué podemos mejorar?',
      extraConfigs: {
        colors: PRODUTURO_PIE_COLORS,
        itemGap: 10,
        center: ['27%', '55%'],
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Satisfacción: clientes satisfechos',
      position: [2, 6],
      size: [2, 2],
      indicator: 'call_center_l_pregunta_3',
      helpText: '¿Qué fue lo que más valoraste?',
      extraConfigs: {
        colors: PRODUTURO_PIE_COLORS,
        itemGap: 10,
        center: ['27%', '55%'],
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Facilidad: clientes pasivos',
      position: [0, 8],
      size: [2, 2],
      helpText: ' ¿Por qué motivos te resultó de esa manera?',
      indicator: 'call_center_l_pregunta_8',
      extraConfigs: {
        colors: PRODUTURO_PIE_COLORS,
        itemGap: 10,
        center: ['27%', '55%'],
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Facilidad: clientes insatisfechos',
      indicator: 'call_center_l_pregunta_7',
      helpText: '¿Qué podemos hacer para que sea sencillo?',
      position: [2, 8],
      size: [2, 2],
      extraConfigs: {
        colors: PRODUTURO_PIE_COLORS,
        itemGap: 10,
        center: ['27%', '55%'],
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Facilidad: clientes satisfechos',
      position: [0, 10],
      size: [2, 2],
      indicator: 'call_center_l_pregunta_9',
      helpText: '¿Por qué motivos te resultó de esa manera?',
      extraConfigs: {
        colors: PRODUTURO_PIE_COLORS,
        itemGap: 10,
        center: ['27%', '55%'],
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Contacto previo',
      position: [2, 10],
      size: [2, 2],
      indicator: 'call_center_l_pregunta_10',
      helpText: '¿Habías intentado resolver tu consulta / trámite a través de alguno de los siguientes canales?',
      extraConfigs: {
        colors: PRODUTURO_PIE_COLORS,
        itemGap: 10,
        center: ['27%', '55%'],
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Atención: clientes insatisfechos',
      position: [0, 12],
      size: [2, 2],
      indicator: 'call_center_l_pregunta_14',
      helpText: '¡Queremos mejorar! Señalaste que la atención fue mala, ¿nos puedes contar por qué?',
      extraConfigs: {
        colors: PRODUTURO_PIE_COLORS,
        itemGap: 10,
        center: ['27%', '55%'],
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMediumSiNo',
      title: 'Efectividad',
      position: [2, 12],
      size: [2, 2],
      indicator: 'call_center_l_pregunta_11',
      helpText: '¿pudiste resolver el motivo de tu llamada?',
      extraConfigs: {
        itemGap: 10,
        center: ['30%', '55%'],
      }
    }),
    getProfuturoCustomerJourney({
        id: 'cj-callCenter',
        position: [0, 14],
        size: [3, 2],
        indicators: [{ name: 'call_center_l_pregunta_2', formula: 'count-number' }, //csat
                     { name: 'call_center_l_pregunta_6', formula: 'count-number' }, // facilidad
                     { name: 'call_center_l_pregunta_17', formula: 'count-number' }, //atencion
                     { name: 'call_center_l_pregunta_35', formula: 'nps-10'}, //nps
        ],
        title: 'Customer Journey: Top Two Box',
        
    }),
    {
      title: 'Atributos de experiencia',
      position: [0, 16],
      size: [4, 1],
      type: 'label',
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'stackedBar',
      title: 'La amabilidad y calidez con la que me atendió',
      helpText: 'Pensando en la atención recibida, ¿qué tan satisfecho estás con cada uno de los siguientes aspectos?',
      position: [0, 17],
      size: [4, 1],
      indicator: 'call_center_l_pregunta_16'
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'stackedBar',
      title: 'Los conocimientos y claridad con el que fui atendido acerca de mi consulta / trámite',
      helpText: 'Pensando en la atención recibida, ¿qué tan satisfecho estás con cada uno de los siguientes aspectos?',
      position: [0, 18],
      size: [4, 1],
      indicator: 'call_center_l_pregunta_20'
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'stackedBar',
      helpText: 'Pensando en la atención recibida, ¿qué tan satisfecho estás con cada uno de los siguientes aspectos?',
      title: 'El compromiso e interés que se mostró para resolver mi consulta / trámite',
      position: [0, 19],
      size: [4, 1],
      indicator: 'call_center_l_pregunta_18'
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'stackedBar',
      helpText: 'Pensando en la atención recibida, ¿qué tan satisfecho estás con cada uno de los siguientes aspectos?',
      title: 'La asesoría que me dieron (orientación) para que tomara una decisión informada',
      position: [0, 20],
      size: [4, 1],
      indicator: 'call_center_l_pregunta_19'
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'wordCount',
      title: 'Consultas no resueltas',
      helpText: '¿Podrías contarnos más acerca de cuál fue la consulta o trámite que no pudiste resolver?',
      position: [0, 21],
      size: [4, 2],
      indicator: 'call_center_l_pregunta_13'
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'textAnalysis',
      title: 'Análisis de Sentimientos',
      position: [0, 23],
      size: [4, 4],
      indicator: 'SENTIMENT'
    }),
    getNpsPorEjecutivoTable({
      size: [4, 2],
      criticalMomentInclude: [10373]
    })
  ]
} as DasboardConfig
