import { filterGeos } from '@utils/GeoUtils'


export const widgetsMultipleTrend: CommonWidget = {


  /**************************************************************************
   *                                MULTIPLE TREND
  ***************************************************************************/

  alertsMultipleTrendByMonth: {
    position: [0, 0],
    size: [0, 0],
    subTitle: 'widgets.alertsCountMonth',
    config: {
      id: 'alerts-multiple-trend-by-month',
      type: 'multipleTrend',
      percentageScale: true,
      //colors: ['#a7eb12', '#D95A26'],
      categories: ['fastPerc', 'slowPerc'],
      showSample: true,
      valueSymbol: '%',
      backgroundColor: '#3A1B52',
      sampleColor: '#5F2F82',
      fontColor: 'white',
      mapper: (data, extra) => {
        const selectedGeos = filterGeos(
          extra['geoLocations'],
          extra['geoLocationsInclude']
        ).length

        return data.map((item: any, idx: any) => ({
          ...item,
          id: idx,
          value: item['fastPerc'] ? item['fastPerc'] : 0,
          fastPerc: item['fastPerc'] ? item['fastPerc'] : 0,
          slowPerc: item['slowPerc'] ? item['slowPerc'] : 0,
          trend:
            item.value && item.value['answeredCount']
              ? item.value['answeredCount'] / selectedGeos
              : 0,
        }))
      },
      indicators: [
        {
          // Este indicador es por defecto
          indicator: 'alert-first-response-time',
          grouped: 'formattedMonth',
          keyExtract: [
            'group',
            'value',
            'groupName',
            'count',
            'fastCount',
            'fastPerc',
            'slowCount',
            'slowPerc',
            'group_0'
          ],
          label: 'groupName',
          value: 'value',
          singleton: true,
          sort: { sortBy: 'group_0', value: 'asc' },
          extras: {
            alertStatus: 'IN_PROCESS,CLOSED',
            groupNameFormat: 'description'
          },
        },
      ],
    },
  },

  alertsMultipleTrendByWeek: {
    subTitle: 'widgets.alertsCountWeek',
    helpLink: '--',
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'alerts-multiple-trend-by-week',
      type: 'multipleTrend',
      percentageScale: true,
      //colors: ['#a7eb12', '#D95A26'],
      categories: ['fastPerc', 'slowPerc'],
      showSample: true,
      valueSymbol: '%',
      backgroundColor: '#3A1B52',
      sampleColor: '#5F2F82',
      fontColor: 'white',
      mapper: (data, extra) => {
        const selectedGeos = filterGeos(
          extra['geoLocations'],
          extra['geoLocationsInclude']
        ).length

        return data.map((item: any, idx: any) => ({
          ...item,
          id: idx,
          groupName: 'Sem. ' + item.group.split('-')[1], //'Sem. ' + idx,
          value: item['fastPerc'] ? item['fastPerc'] : 0,
          fastPerc: item['fastPerc'] ? item['fastPerc'] : 0,
          slowPerc: item['slowPerc'] ? item['slowPerc'] : 0,
          trend:
            item.value && item.value['answeredCount']
              ? item.value['answeredCount'] / selectedGeos
              : 0,
        }))
      },
      indicators: [
        {
          indicator: 'alert-first-response-time',
          grouped: 'formattedWeek',
          keyExtract: [
            'group',
            'group_0',
            'value',
            'groupName',
            'count',
            'fastCount',
            'fastPerc',
            'slowCount',
            'slowPerc',
          ],
          label: 'group',
          value: 'value',
          singleton: true,
          sort: { sortBy: 'group_0', value: 'asc' },
          extras: {
            alertStatus: 'IN_PROCESS,CLOSED',
            periods: 13
          },
        },
      ],
    },
  },
  alertsMultipleTrendByGeo: {
    subTitle: 'Respuesta alertas por geo',
    helpLink: '--',
    position: [0, 36],
    size: [4, 2],
    config: {
      id: 'alerts-multiple-trend-by-geo',
      type: 'multipleTrend',
      percentageScale: true,
      //colors: ['#a7eb12', '#D95A26'],
      categories: ['fastPerc', 'slowPerc'],
      showSample: true,
      valueSymbol: '%',
      backgroundColor: '#3A1B52',
      sampleColor: '#5F2F82',
      fontColor: 'white',
      mapper: (data, extra) => {
        const selectedGeos = filterGeos(
          extra['geoLocations'],
          extra['geoLocationsInclude']
        ).length

        return data.map((item: any, idx: any) => ({
          ...item,
          id: idx,
          value: item['fastPerc'] ? item['fastPerc'] : 0,
          fastPerc: item['fastPerc'] ? item['fastPerc'] : 0,
          slowPerc: item['slowPerc'] ? item['slowPerc'] : 0,
          trend:
            item.value && item.value['answeredCount']
              ? item.value['answeredCount'] / selectedGeos
              : 0,
        }))
      },
      indicators: [
        {
          indicator: 'alert-first-response-time',
          grouped: 'geoLocation',
          keyExtract: [
            'group',
            'value',
            'groupName',
            'count',
            'fastCount',
            'fastPerc',
            'slowCount',
            'slowPerc',
          ],
          label: 'group',
          value: 'value',
          singleton: true,
          sort: { sortBy: 'group', value: 'asc' },
          extras: {
            alertStatus: 'IN_PROCESS,CLOSED',
          },
        },
      ],
    },
  },
  multipleTrendByMonth: {
    title: 'TODO',
    position: [3, 2],
    size: [1, 2],
    config: {
      id: 'multiple-trend-by-month',
      type: 'multipleTrend',
      color: '#CCC',
      showHistoric: false,
      showSample: true,
      categories: ['promoters', 'passives', 'detractors'],
      colors: ['#88DD76', '#34C0B8', '#DB7476'],
      decimals: 2,
      indicators: [
        {
          indicator: 'TODO',
          grouped: 'formattedMonth',
          keyExtract: [
            'passives',
            'detractors',
            'promoters',
            'passiveCount',
            'detractorCount',
            'promoterCount',
            'value',
            'groupName',
            'group',
            'count',
          ],
          value: 'value',
          singleton: true,
          sort: { 'sortBy': 'group', value: 'asc', },
          extras: {
            periods: 6
          },
        },
      ],
    },
  },
  multipleTrendByBimonthly: {
    title: 'TODO',
    position: [3, 2],
    size: [1, 2],
    config: {
      id: 'multiple-trend-by-month',
      type: 'multipleTrend',
      color: '#CCC',
      showHistoric: false,
      showSample: true,
      categories: ['promoters', 'passives', 'detractors'],
      colors: ['#88DD76', '#34C0B8', '#DB7476'],
      decimals: 2,
      indicators: [
        {
          indicator: 'TODO',
          grouped: 'formattedBimonthlyPeriod',
          keyExtract: [
            'passives',
            'detractors',
            'promoters',
            'passiveCount',
            'detractorCount',
            'promoterCount',
            'value',
            'groupName',
            'group',
            'count',
          ],
          value: 'value',
          singleton: true,
          sort: { 'sortBy': 'group', value: 'asc', },
          extras: {
            periods: 6
          },
        },
      ],
    },
  },
}
