import { COLORS_CONFIG_BAR, COLORS_CONFIG_GAUGE_5 } from '@components/widgets/apacheEchart/Commons'
import { getCommonWidget } from '../dashWidgetHelper'

import { bydAnsweredMapper, bydInvalidMapper, commonWidgets, bydAnsweredMapperServicio } from './commonWidgetsByd'
import { bydCustomerJourneyServicioMapper, bydTooltipTotalEncuestas } from './cxoResume'
import { getLineDetailedRadar } from '../widgetsLibrary/widgetsLine'

export default {
  title: 'Nacional Postventa Dashboard',
  description: 'Dashboard de Nacional Postventa',
  backgroundClassName: (filters) => 'epsilon-hero-bg',
  widgets: (filters: FiltersUI) => [
    /* getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'lineDetailedByMonthAndGeo',
      title: 'widgets.snPostventa',
      position: [0, 0],
      size: [4, 4],
      extraConfigs: {
        backgroundColor: 'transparent',
        theme: 'dark',
      },
      indicator: 'SERVICIO_SATISFACCION',
      criticalMomentInclude: [2],
    }), */
    getLineDetailedRadar({
      mainIndicator: 'SERVICIO_SATISFACCION',
      radialIndicators: [
        'SERVICIO_SATISFACCION',
        'SERVICIO_NPS',
        'SERVICIO_EXPLICACION_TRABAJO',
        'SERVICIO_SEGUIMIENTO',
        'SERVICIO_RECEPCION_VEHICULO'
      ],
      radialType: 'byIndicator',
      position: [0, 0],
      size: [4, 4],
      title: 'widgets.snByGeo',
      extraConfigs: {
        backgroundColor: 'transparent',
        theme: 'dark',
        showRadialChart: true,
        initialVisibleChart: 1,
        decimals: 1,
      },
      criticalMomentInclude: [2],
      groupByLevel: 2,
      indicatorsNames: {
        'SERVICIO_SATISFACCION': 'Satisfacción',
        'SERVICIO_NPS': 'NPS',
        'SERVICIO_EXPLICACION_TRABAJO': 'Explicacion final',
        'SERVICIO_SEGUIMIENTO': 'Seguimiento',
        'SERVICIO_RECEPCION_VEHICULO': 'Bienvenida'
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'surveyCountTrendByMonth',
      position: [0, 4],
      size: [1, 2],
      indicator: 'SURVEY_COUNT',
      criticalMomentId: 2,
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'surveyCountTrendByWeek',
      position: [1, 4],
      size: [1, 2],
      indicator: 'SURVEY_COUNT',
      criticalMomentId: 2,
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'alertsTrendByMonth',
      subTitle: 'widgets.alertsCountMonth',
      position: [2, 4],
      size: [1, 2],
      indicator: 'ALERT_FIRST_RESPONSE_TIME',
      criticalMomentId: 2,
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'alertsTrendByWeek',
      subTitle: 'widgets.alertsCountWeek',
      position: [3, 4],
      size: [1, 2],
      indicator: 'ALERT_FIRST_RESPONSE_TIME',
      criticalMomentId: 2,
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.snPostventa',
      position: [0, 6],
      size: [2, 2],
      indicator: 'SERVICIO_SATISFACCION',
      criticalMomentId: 2,
      extraConfigs: {
        decimals: 1
      }
    }),
    /*
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'semicircleGeneric',
      title: 'widgets.npsPostventa',
      position: [1, 6],
      size: [1, 2],
      indicator: 'SERVICIO_NPS',
      criticalMomentId: 2,
      extraConfigs: {
        decimals: 0
      },
      extras: {
        periods: 6
      }
    }),
    */
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.calidadDatosPostventa',
      indicator: 'survey-response-counters',
      key: 'posventa-calidad-datos-gauge',
      position: [2, 6],
      size: [1, 2],
      mapper: bydInvalidMapper,
      tooltipFormatter: bydTooltipTotalEncuestas,
      criticalMomentId: 2,
      extraConfigs: {
        decimals: 1,
        maxValue: 100,
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.porcRespuestasPostventa',
      indicator: 'survey-response-counters',
      key: 'posventa-porc-respuestas-gauge',
      mapper: bydAnsweredMapperServicio,
      tooltipFormatter: bydTooltipTotalEncuestas,
      // [Column, Row]
      position: [3, 6],
      size: [1, 2],
      criticalMomentId: 2,
      extraConfigs: {
        decimals: 1,
        maxValue: 100,
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleGaugeByQuestion',
      title: 'widgets.customerJourneyPostventa',
      position: [0, 8],
      size: [4, 2],
      indicator: 'SERVICIO_CUSTOMER_JOURNEY',
      mapper: bydCustomerJourneyServicioMapper,
      criticalMomentId: 2,
      extraConfigs: {
        decimals: 1,
        colors: COLORS_CONFIG_GAUGE_5,
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoBest',
      title: 'widgets.bestGeoLocationsPostventa',
      position: [0, 10],
      size: [1, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76'
      },
      indicator: 'SERVICIO_SATISFACCION',
      criticalMomentId: 2,
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoWorst',
      title: 'widgets.worstGeoLocationsPostventa',
      position: [1, 10],
      size: [1, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#db7476'
      },
      indicator: 'SERVICIO_SATISFACCION',
      criticalMomentId: 2,
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'mapByGeos',
      title: 'Mapa de estaciones',
      position: [2, 10],
      size: [2, 2],
      indicator: 'SERVICIO_SATISFACCION',
      criticalMomentInclude: [1],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByQuestionBest',
      title: 'widgets.bestRotativasPostventa',
      position: [0, 12],
      size: [1, 2],
      extraConfigs: {
        fontSize: 20
      },
      indicator: 'SERVICIO_ROTATIVAS',
      criticalMomentId: 2
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByQuestionWorst',
      title: 'widgets.worstRotativasPostventa',
      position: [1, 12],
      size: [1, 2],
      extraConfigs: {
        fontSize: 20
      },
      indicator: 'SERVICIO_ROTATIVAS',
      criticalMomentId: 2
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByTagBest',
      title: 'widgets.bestTagsPostventa',
      position: [2, 12],
      size: [2, 1],
      indicator: 'SENTIMENT',
      criticalMomentId: 2,
      extras: {
        trunc: 'HALF_DOWN',
        topSize: 3,
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByTagWorst',
      title: 'widgets.worstTagsPostventa',
      position: [2, 13],
      size: [2, 1],
      indicator: 'SENTIMENT',
      criticalMomentId: 2,
      extras: {
        resultType: 'bottom',
        trunc: 'HALF_UP',
        bottomSize: 3,
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'textAnalysis',
      title: 'Análisis de sentimientos',
      indicator: 'SENTIMENT',
      criticalMomentId: 2,
      position: [0, 14],
      size: [4, 3],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'diagramaIshikawa',
      title: 'Diagrama Ishikawa',
      position: [0, 17],
      size: [4, 2],
      indicator: 'SENTIMENT',
      criticalMomentId: 2,
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'barByGeo',
      title: 'widgets.zonalSatisfaction',
      key: 'postventa-satisfaccion-zona',
      indicator: 'SERVICIO_SATISFACCION',
      criticalMomentId: 2,
      position: [0, 19],
      size: [4, 2],
      mapper: (data: any) => {
        return data.sort((a: any, b:any) => b.value - a.value)
      },
      extraConfigs: {
        colors: COLORS_CONFIG_BAR,
        wrapTexts: true,
      },
      extras: {
        groupByLevel: 2
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'barByGeo',
      title: 'widgets.distribuidorSatisfaction',
      key: 'postventa-satisfaccion-dist',
      indicator: 'SERVICIO_SATISFACCION',
      criticalMomentId: 2,
      position: [0, 21],
      size: [4, 2],
      mapper: (data: any) => {
        return data.sort((a: any, b:any) => b.value - a.value)
      },
      extraConfigs: {
        colors: COLORS_CONFIG_BAR,
        wrapTexts: false,
        rotateXAxisLegend: true
      },
      extras: {
        groupByLevel: 3,
      },
    }),
  ],
} as DasboardConfig
