import useClientConfig from 'config/clients/useClientConfig'
import { renderForCriticalMoment, renderForGeoLocation } from '../../dashHelper'
import { getCommonWidget } from '../../dashWidgetHelper'
import {
  HA_QUARTER_COLORS,
  commonWidgets
} from './../commonWidgetsHa'
import { getHaBarDetailedByMonthAndGeo, getHaLineDetailedByLeafMonthAndGeo, getHaLineDetailedByMonthAndGeo } from '../widgetsFunctionsHa'

const ROOT_WIDGET = 'root-widget'
const LEAF_WIDGET = 'leaf-widget'

const getAllWidgets = (geoRootId: number, cmRootId: number) => [
  {
    title: 'Hospitales',
    type: 'label',
    size: [4, 1]
  },
  /**
   * NPS
   */
  getHaLineDetailedByMonthAndGeo({
    key: `nps-${ROOT_WIDGET}`,
    indicator: 'NPS_GENERAL',
    title: 'NPS General por región',
    size: [4, 4],
    formula: 'nps-10',
    extraConfigs: {
      backgroundColor: '#3a1b52',
      theme: 'dark',
    },
    criticals: [
      { key: 'durante', criticalMoments: [9951], label: 'Durante' },
      { key: 'posterior', criticalMoments: [10301, 11301], label: 'Posterior' }
    ]
  }),
  getHaBarDetailedByMonthAndGeo({
    key: `nps-${ROOT_WIDGET}`,
    indicator: 'NPS_GENERAL',
    title: 'NPS General por región',
    size: [4, 4],
    formula: 'nps-10',
    extraConfigs: {
      backgroundColor: '#3a1b52',
      theme: 'dark',
    },
    criticals: [
      { key: 'durante', criticalMoments: [9951], label: 'Durante' },
      { key: 'posterior', criticalMoments: [10301, 11301], label: 'Posterior' }
    ]
  }),
  /**
   * Satisfacción
   */
  getHaLineDetailedByMonthAndGeo({
    key: `csat-${ROOT_WIDGET}`,
    indicator: 'CSAT_GENERAL',
    title: 'Satisfacción General por mes',
    size: [4, 4],
    formula: 'nps-5',
    extraConfigs: {
      backgroundColor: '#3a1b52',
      theme: 'dark',
    },
    criticals: [
      { key: 'durante', criticalMoments: [9951], label: 'Durante' },
      { key: 'posterior', criticalMoments: [10301, 11301], label: 'Posterior' }
    ]
  }),
  getHaBarDetailedByMonthAndGeo({
    key: `csat-${ROOT_WIDGET}`,
    indicator: 'CSAT_GENERAL',
    title: 'Satisfacción General por región',
    size: [4, 4],
    formula: 'nps-5',
    extraConfigs: {
      backgroundColor: '#3a1b52',
      theme: 'dark',
    },
    criticals: [
      { key: 'durante', criticalMoments: [9951], label: 'Durante' },
      { key: 'posterior', criticalMoments: [10301, 11301], label: 'Posterior' }
    ]
  }),
  /**
   * CSAT y NPS - Leafs
   */
  getHaLineDetailedByLeafMonthAndGeo({
    key: `nps-${LEAF_WIDGET}`,
    indicator: 'NPS_GENERAL',
    title: 'NPS General por mes',
    size: [4, 4],
    extraConfigs: {
      backgroundColor: '#3a1b52',
      theme: 'dark',
    }
  }),
  getHaLineDetailedByLeafMonthAndGeo({
    key: `csat-${LEAF_WIDGET}`,
    indicator: 'CSAT_GENERAL',
    title: 'Satisfacción General por mes',
    size: [4, 4],
    extraConfigs: {
      backgroundColor: '#3a1b52',
      theme: 'dark',
    }
  }),
  {
    title: 'Hospitales - Análisis de datos',
    type: 'label',
    size: [4, 1]
  },
  getCommonWidget({
    commonWidgets: commonWidgets,
    title: 'Ranking NPS mejores hospitales',
    name: 'barByGeo',
    size: [4, 2],
    indicator: 'NPS_GENERAL',
    extraIndicatorConfigs: {
      sort: { value: 'desc' },
      limit: 6
    },
    extraConfigs: {
      wrapTexts: true
    },
    extras: {
      groupByLevel: 3
    },
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    title: 'Ranking NPS hospitales a mejorar',
    name: 'barByGeo',
    size: [4, 2],
    indicator: 'NPS_GENERAL',
    extraIndicatorConfigs: {
      sort: { sortBy: 'value', value: 'asc' },
      limit: 6
    },
    extraConfigs: {
      wrapTexts: true
    },
    extras: {
      groupByLevel: 3
    },
  }),
  {
    title: 'Matriz de Priorización',
    type: 'label',
    size: [4, 1],
    //criticalMomentInclude: [10301]
    //config: { color: '#B7AADC' },
  },
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'quarterTrend',
    extraConfigs: {
      colors: HA_QUARTER_COLORS,
      headersX:['Malo', 'Regular', 'Bueno'] 
    },
    size: [4, 3],
  }),
]

export default {
  title: 'Default Dashboard',
  description: 'Dashboard de perfil Default',
  widgets: (filters: FiltersUI) => {
    const { getCriticalMomentsRootId, getGeosRootId } = useClientConfig()
    const cmRootId = getCriticalMomentsRootId()
    const geoRootId = getGeosRootId()
    const isCmRoot = renderForCriticalMoment(filters, [cmRootId, 0])
    const isGeoRoot = renderForGeoLocation(filters, [geoRootId])

    //console.log(`Rendering ha dashboard - isCmRoot: ${isCmRoot} - isGeoRoot: ${isGeoRoot}`)
    //console.log(filters)

    const finalWidgets = getAllWidgets(geoRootId, cmRootId)
      .filter((wdg: any) => {
        return (isCmRoot && isGeoRoot && wdg?.widgetName?.includes(ROOT_WIDGET))
          || (isCmRoot && !isGeoRoot && wdg?.widgetName?.includes(LEAF_WIDGET))
          || (!wdg.widgetName?.includes(ROOT_WIDGET)
            && !wdg.widgetName?.includes(LEAF_WIDGET))
      })
    //console.log(finalWidgets)

    return finalWidgets
  },
} as DasboardConfig
