import { COLORS_CONFIG, COLORS_CONFIG_BAR } from '@components/widgets/apacheEchart/Commons'
import { answeredMapper, invalidMapper } from '../dashHelper'
import { getCommonWidget } from '../dashWidgetHelper'
import {commonWidgets, MITSUBISHI_SCALE_3_COLORS} from './commonWidgetsMitsu'

export default {
  title: 'Zonal Venta Dashboard',
  description: 'Dashboard de Zonal Venta',
  widgets: (filters: FiltersUI) => [
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.snVenta',
      position: [0, 0],
      size: [1, 2],
      indicator: 'VENTA_SATISFACCION',
      criticalMomentId: 1
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleTrendByMonth',
      title: 'widgets.npsVenta',
      position: [1, 0],
      size: [1, 2],
      indicator: 'VENTA_NPS',
      criticalMomentId: 1,
      extras: {
        periods: 6
      },
      extraConfigs: {
        useCurrent: 0,
        colors: MITSUBISHI_SCALE_3_COLORS
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: 'widgets.calidadDatosVenta',
      position: [2, 0],
      size: [1, 2],
      mapper: invalidMapper,
      url: 'survey-responses/values',
      criticalMomentId: 1
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: 'widgets.porcRespuestasVenta',
      position: [3, 0],
      size: [1, 2],
      mapper: answeredMapper,
      url: 'survey-responses/values',
      criticalMomentId: 1
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleGaugeByQuestion',
      title: 'widgets.customerJourneyVenta',
      position: [0, 2],
      size: [4, 2],
      indicator: 'VENTA_CUSTOMER_JOURNEY',
      criticalMomentId: 1
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByQuestionBest',
      title: 'widgets.bestRotativasVenta',
      position: [0, 4],
      size: [2, 2],
      indicator: 'VENTA_ROTATIVAS',
      criticalMomentId: 1
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByQuestionWorst',
      title: 'widgets.worstRotativasVenta',
      position: [2, 4],
      size: [2, 2],
      indicator: 'VENTA_ROTATIVAS',
      criticalMomentId: 1
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoBest',
      title: 'widgets.bestGeoLocationsVenta',
      position: [0, 6],
      size: [2, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76'
      },
      indicator: 'VENTA_SATISFACCION',
      criticalMomentInclude: [1, 11703],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoWorst',
      title: 'widgets.worstGeoLocationsVenta',
      position: [2, 6],
      size: [2, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#db7476'
      },
      indicator: 'VENTA_SATISFACCION',
      criticalMomentInclude: [1, 11703],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByTagBest',
      title: 'widgets.bestTagsVenta',
      position: [0, 8],
      size: [2, 2],
      indicator: 'sentiment',
      criticalMomentId: 1,
      extras: {
        trunc: 'HALF_DOWN',
        topSize: 3,
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByTagWorst',
      title: 'widgets.worstTagsVenta',
      position: [2, 8],
      size: [2, 2],
      indicator: 'sentiment',
      criticalMomentId: 1,
      extras: {
        resultType: 'bottom',
        trunc: 'HALF_UP',
        bottomSize: 3,
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'textAnalysis',
      title: 'widgets.sentimentAnalysis',
      position: [0, 10],
      size: [4, 4],
      criticalMomentId: 1,
    }),
    {
      title: 'widgets.zoneGeneralSatisfaction',
      position: [0, 14],
      size: [4, 1],
      config: {
        id: 'ranking-zonas',
        type: 'rankingIPN',
        indicators: [
          {
            indicator: 'VENTA_SATISFACCION',
            keyExtract: ['group', 'position', 'groupCount'],
            grouped: 'geoLocation',
            label: 'group',
            value: 'position',
            singleton: true,
            sort: { value: 'desc' },
            limit: 1,
            extras: {
              aggregation: 'rankingPosition',
              rankingPositionScope: 100,
            },
          },
        ],
      },
    },
    {
      title: 'widgets.distributorGeneralSatisfaction',
      // [Column, Row]
      position: [0, 15],
      size: [4, 2],
      config: {
        id: 'satisfaccion-dist',
        type: 'bar',
        fillMode: '',
        //colors: '#f38625',
        colors: COLORS_CONFIG_BAR,
        showValues: true,
        percentageScale: false,
        decimals: 0,
        indicators: [
          {
            indicator: 'VENTA_SATISFACCION',
            grouped: 'geoLocation',
            keyExtract: ['group', 'value'],
            label: 'group',
            value: 'value',
            singleton: true,
            extras: {
              groupByLevel: 3,
              criticalMomentId: 1,
            },
          },
        ],
      },
    },
    {
      title: 'widgets.driveTestReasonsVenta',
      // [Column, Row]
      position: [0, 17],
      size: [2, 2],
      config: {
        id: 'venta-no-prueba',
        type: 'pie',
        fillMode: '',
        colors: ['#015c52', '#33b9ce', '#f38625'],
        showValues: true,
        //percentageScale: false,
        mapper: (data) => {
          return data.map((item) => ({
            id: item['group'] == null ? 'Indefinido' : item['group'],
            label: item['group'] == null ? 'Indefinido' : item['group'],
            value: item['value'],
          }))
        },
        indicators: [
          {
            indicator: 'VENTA_SIN_PRUEBA_MANEJO_MOTIVO',
            //grouped: 'geoLocation',
            keyExtract: ['group', 'count', 'value'],
            label: 'group',
            value: 'value',
            singleton: true,
            extras: {
              criticalMomentId: 1,
            },
          },
        ],
      },
    },
    {
      title: 'widgets.financingReasonsVenta',
      // [Column, Row]
      position: [2, 17],
      size: [2, 2],
      config: {
        id: 'venta-no-financiacion',
        type: 'pie',
        fillMode: '',
        colors: ['#015c52', '#33b9ce', '#f38625'],
        showValues: true,
        //percentageScale: false,
        mapper: (data) => {
          return data.map((item) => ({
            id: item['group'] == null ? 'Indefinido' : item['group'],
            label: item['group'] == null ? 'Indefinido' : item['group'],
            value: item['value'],
          }))
        },
        indicators: [
          {
            indicator: 'VENTA_SIN_FINANCIAMIENTO_MOTIVO',
            //grouped: 'geoLocation',
            keyExtract: ['group', 'count', 'value'],
            label: 'group',
            value: 'value',
            singleton: true,
            extras: {
              criticalMomentId: 1,
            },
          },
        ],
      },
    },
    {
      title: 'widgets.instalationsVenta',
      // [Column, Row]
      position: [0, 19],
      size: [1, 2],
      config: {
        id: 'venta-instalaciones',
        type: 'gauge',
        fillMode: '',
        //color: '#33b9ce',
        color: COLORS_CONFIG,
        showValues: true,
        maxValue: 5,
        decimals: 1,
        indicators: [
          {
            indicator: 'VENTA_INSTALACIONES',
            keyExtract: ['value'],
            value: 'value',
            extras: {
              criticalMomentId: 1,
            },
          },
        ],
      },
    },
    {
      title: 'widgets.parkingVenta',
      // [Column, Row]
      position: [1, 19],
      size: [1, 2],
      config: {
        id: 'venta-estacionamiento',
        type: 'gauge',
        fillMode: '',
        //color: '#33b9ce',
        color: COLORS_CONFIG,
        showValues: true,
        maxValue: 5,
        decimals: 1,
        indicators: [
          {
            indicator: 'VENTA_ESTACIONAMIENTO',
            keyExtract: ['value'],
            value: 'value',
            extras: {
              criticalMomentId: 1,
            },
          },
        ],
      },
    },
    {
      title: 'widgets.upsellingVenta',
      // [Column, Row]
      position: [2, 19],
      size: [1, 2],
      config: {
        id: 'venta-accesorios-adicionales',
        type: 'gauge',
        fillMode: '',
        //color: '#33b9ce',
        color: COLORS_CONFIG,
        showValues: true,
        maxValue: 5,
        decimals: 1,
        indicators: [
          {
            indicator: 'VENTA_ACCESORIOS_ADICIONALES',
            keyExtract: ['value'],
            value: 'value',
            extras: {
              criticalMomentId: 1,
            },
          },
        ],
      },
    },
    {
      title: 'widgets.carStatusVenta',
      // [Column, Row]
      position: [3, 19],
      size: [1, 2],
      config: {
        id: 'venta-estado-vehiculo',
        type: 'gauge',
        fillMode: '',
        //color: '#33b9ce',
        color: COLORS_CONFIG,
        showValues: true,
        maxValue: 5,
        decimals: 1,
        indicators: [
          {
            indicator: 'VENTA_ESTADO_VEHICULO',
            keyExtract: ['value'],
            value: 'value',
            extras: {
              criticalMomentId: 1,
            },
          },
        ],
      },
    },
    {
      title: 'widgets.warrantyExplanation',
      // [Column, Row]
      position: [0, 21],
      size: [1, 2],
      config: {
        id: 'venta-garantia',
        type: 'gauge',
        fillMode: '',
        //color: '#33b9ce',
        color: COLORS_CONFIG,
        showValues: true,
        maxValue: 5,
        decimals: 1,
        indicators: [
          {
            indicator: 'VENTA_GARANTIA',
            keyExtract: ['value'],
            value: 'value',
            extras: {
              criticalMomentId: 1,
            },
          },
        ],
      },
    },
  ],
} as DasboardConfig
