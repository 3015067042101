import { getHaLineDetailedByMonthAndGeo } from '../widgetsFunctionsHa'


export default {
  title: 'CEDIASA Dashboard',
  description: 'Dashboard de perfil CEDIASA',
  widgets: (filters: FiltersUI, filtersData: Record<string, any>) => [
    {
      title: 'CEDIASA',
      type: 'label',
      size: [4, 1]
    },
    /**
   * NPS
   */
    getHaLineDetailedByMonthAndGeo({
      key: `nps-line`,
      indicator: 'NPS_CEDIASA',
      title: 'NPS General por mes cediasa',
      size: [4, 4],
      formula: 'nps-10',
      extraConfigs: {
        backgroundColor: '#3a1b52',
        theme: 'dark',
      },
      criticals: [
        { key: 'csh', criticalMoments: [12052], label: 'Convenios Serv. Sub. de Hemodiálisis' },
        { key: 'avu', criticalMoments: [11655], label: 'Serv. de Acc. Vasculares' },
        { key: 'hau', criticalMoments: [11653], label: 'Serv. de Hemodiálisis Amb.' }
      ]
    }),
    /**
     * Satisfacción
     */
    getHaLineDetailedByMonthAndGeo({
      key: `csat-line`,
      indicator: 'CSAT_CEDIASA',
      title: 'Satisfacción General por mes cediasa',
      size: [4, 4],
      formula: 'nps-5',
      extraConfigs: {
        backgroundColor: '#3a1b52',
        theme: 'dark',
      },
      criticals: [
        { key: 'csh', criticalMoments: [12052], label: 'Convenios Serv. Sub. de Hemodiálisis' },
        { key: 'avu', criticalMoments: [11655], label: 'Serv. de Acc. Vasculares' },
        { key: 'hau', criticalMoments: [11653], label: 'Serv. de Hemodiálisis Amb.' }
      ]
    })
  ],
} as DasboardConfig
