import { getCommonWidget } from "../../dashWidgetHelper";
import { PRODUTURO_PIE_COLORS, commonWidgets, getProfuturoCustomerJourney, getProfuturoFacilidad, getProfuturoNpsAcumulado, getProfuturoNpsPorMes, getProfuturoSatisfaccion } from "../commonWidgetsProfuturo";

export default {
  title: 'Acompañamiento Dashboard',
  description: 'Acompañamiento Dashboard',
  backgroundClassName: (filters: FiltersUI) => 'epsilon-hero-bg',
  widgets: (filters: FiltersUI) => [
    {
      title: 'Principales Indicadores',
      position: [0, 0],
      size: [4, 1],
      type: 'label',
      config: { color: '#B7AADC' },
    },
    getProfuturoNpsAcumulado({
      position: [0, 1],
      size: [1, 2],
      indicator: 'whatsapp_albapregunta_24'
    }),
    getProfuturoNpsPorMes({
      position: [1, 1],
      size: [1, 2],
      indicator: 'whatsapp_albapregunta_24',
    }),
    getProfuturoSatisfaccion({
      position: [2, 1],
      size: [1, 2],
      indicator: 'whatsapp_albapregunta_2',
      helpText: '¿Qué tan satisfecho estás con tu interacción con Alba a través de WhatsApp?'
    }),
    getProfuturoFacilidad({
      position: [3, 1],
      size: [1, 2],
      indicator: 'whatsapp_albapregunta_5',
      helpText: ' ¿Qué tan fácil te resultó interactuar con Alba?'
    }),

    getProfuturoCustomerJourney({
      id: 'cj-whatsapp-alba',
      title: 'Customer Journey: Top Two Box',
      size: [4, 2],
      indicators: [
        { name: 'whatsapp_albapregunta_2', formula: 'count-number', label: 'Satisfacción' }, // csat
        { name: 'whatsapp_albapregunta_5', formula: 'count-number', label: 'Facilidad' }, // Facilidad
        { name: 'whatsapp_albapregunta_24', formula: 'nps-10', label: 'NPS' }, //nps
      ],
    }),
    {
      title: 'Motivadores y quiebres',
      position: [0, 3],
      size: [4, 1],
      type: 'label',
      config: { color: '#B7AADC' },
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericLarge',
      title: 'Satisfacción: clientes insatisfechos',
      position: [0, 4],
      size: [4, 2],
      indicator: 'whatsapp_albapregunta_3',
      helpText: '¿Por qué calificaste de esa manera tu interacción con Alba?',
      extraConfigs: {
        colors: PRODUTURO_PIE_COLORS,
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Facilidad: clientes satisfechos',
      position: [0, 6],
      size: [2, 2],
      indicator: 'whatsapp_albapregunta_10',
      helpText: '¿Por qué fue fácil?',
      extraConfigs: {
        colors: PRODUTURO_PIE_COLORS,
        itemGap: 10,
        center: ['20%', '55%'],
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Facilidad: clientes insatisfechos',
      position: [2, 6],
      size: [2, 2],
      indicator: 'whatsapp_albapregunta_6',
      helpText: 'Señalaste que la interacción con Alba no fue fácil, ¿por qué motivos?',
      extraConfigs: {
        colors: PRODUTURO_PIE_COLORS,
        itemGap: 10,
        center: ['20%', '55%'],
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Facilidad: clientes neutros',
      position: [0, 8],
      size: [2, 2],
      indicator: 'whatsapp_albapregunta_8',
      helpText: '¿En qué podríamos mejorar para que la interacción con Alba sea fácil?',
      extraConfigs: {
        colors: PRODUTURO_PIE_COLORS,
        itemGap: 10,
        center: ['20%', '55%'],
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMediumSiNo',
      title: 'Efectividad',
      position: [2, 8],
      size: [2, 2],
      indicator: 'whatsapp_albapregunta_14',
      helpText: '¿Pudiste resolver todo lo que necesitabas?',
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Efectividad: consultas no resueltas',
      position: [0, 10],
      size: [2, 2],
      indicator: 'whatsapp_albapregunta_15',
      helpText: '¿Qué consulta/trámite no pudiste resolver a través de Alba?',
      extraConfigs: {
        colors: PRODUTURO_PIE_COLORS,
        itemGap: 10,
        center: ['20%', '55%'],
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Interacción previa con Alba',
      position: [2, 10],
      size: [2, 2],
      indicator: 'whatsapp_albapregunta_12',
      helpText: '. ¿Con qué canales interactuaste antes de llegar con Alba en WhatsApp?',
      extraConfigs: {
        colors: PRODUTURO_PIE_COLORS,
      }
    }),
    {
      title: 'Atributos de experiencia',
      position: [0, 12],
      size: [4, 1],
      type: 'label',
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'stackedBar',
      title: 'El tiempo que llevó realizar mis consultas/trámites fue el apropiado',
      position: [0, 13],
      size: [4, 1],
      indicator: 'whatsapp_albapregunta_18',
      helpText: 'Pensando en tu interacción con Alba, ¿qué tan de acuerdo te encuentras con las siguientes frases?',
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'stackedBar',
      title: 'En general, Alba comprendió las frases o preguntas que le realicé.',
      position: [0, 14],
      size: [4, 1],
      indicator: 'whatsapp_albapregunta_19',
      helpText: 'Pensando en tu interacción con Alba, ¿qué tan de acuerdo te encuentras con las siguientes frases?',
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'stackedBar',
      title: 'La conversación fue fluida durante la interacción.',
      position: [0, 15],
      size: [4, 1],
      indicator: 'whatsapp_albapregunta_20',
      helpText: 'Pensando en tu interacción con Alba, ¿qué tan de acuerdo te encuentras con las siguientes frases?',
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'stackedBar',
      title: 'Me ofreció soluciones adecuadas a mis necesidades.',
      position: [0, 16],
      size: [4, 1],
      indicator: 'whatsapp_albapregunta_21',
      helpText: 'Pensando en tu interacción con Alba, ¿qué tan de acuerdo te encuentras con las siguientes frases?',
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'stackedBar',
      title: 'Me ahorró la necesidad de realizar consultas/trámites a través de otros canales.',
      position: [0, 17],
      size: [4, 1],
      indicator: 'whatsapp_albapregunta_22',
      helpText: 'Pensando en tu interacción con Alba, ¿qué tan de acuerdo te encuentras con las siguientes frases?',
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'stackedBar',
      title: 'Me resulta un canal seguro y confiable.',
      position: [0, 18],
      size: [4, 1],
      indicator: 'whatsapp_albapregunta_23',
      helpText: 'Pensando en tu interacción con Alba, ¿qué tan de acuerdo te encuentras con las siguientes frases?',
    }),
    getCommonWidget({
        commonWidgets: commonWidgets,
        name: 'textAnalysis',
        title: 'Análisis de Sentimientos',
        position: [0, 19],
        size: [4, 4],
        indicator: 'SENTIMENT'
    }),
  ]
} as DasboardConfig
