import { getCommonWidget } from '../../dashWidgetHelper'
import { commonWidgets, haTrendDataMapper, haWordCountWithLinksMapper } from './../commonWidgetsHa'

const allWidgets = [
  {
    title: 'NPS',
    type: 'label',
    position: [0, 0],
    size: [1, 1],
    config: { color: '#B7AADC' },
  },
  {
    title: 'Evaluación General',
    type: 'label',
    position: [0, 3],
    size: [3, 1],
    config: { color: '#B7AADC' },
  },
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'NPS',
    position: [0, 1],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'laboratorios_medicos_solicitantes_pregunta_15',
    criticalMomentInclude: [11652],
    extraConfigs: {
      valueSymbol: ''
    }
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'pieGenericSmall',
    title: 'Servicio Solicitado',
    position: [1, 1],
    size: [3, 2],
    extraConfigs: {
      showSample: true,
      valueSymbol: ''
    },
    indicator: 'laboratorios_medicos_solicitantes_pregunta_6',
    criticalMomentInclude: [11652]
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Satisfacción general con los servicios solicitados',
    position: [0, 4],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'laboratorios_medicos_solicitantes_pregunta_9',
    criticalMomentInclude: [11652],
    extraConfigs: {
      valueSymbol: ''
    }
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Variedad del catálogo de servicios',
    position: [1, 4],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'laboratorios_medicos_solicitantes_pregunta_10',
    criticalMomentInclude: [11652],
    extraConfigs: {
      valueSymbol: ''
    }
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Tiempo de entrega de los resultados del servicio ordinario',
    position: [2, 4],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'laboratorios_medicos_solicitantes_pregunta_11',
    criticalMomentInclude: [11652],
    extraConfigs: {
      valueSymbol: ''
    }
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Informe y fiabilidad de los resultados',
    position: [3, 4],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'laboratorios_medicos_solicitantes_pregunta_19',
    criticalMomentInclude: [11652],
    extraConfigs: {
      valueSymbol: ''
    }
  }),
  {
    title: 'Evaluación General',
    type: 'label',
    position: [0, 6],
    size: [4, 1],
  },
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Tiempo de entrega de los resultados del servicio de urgencias',
    position: [0, 7],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'laboratorios_medicos_solicitantes_pregunta_13',
    criticalMomentInclude: [11652],
    extraConfigs: {
      valueSymbol: ''
    }
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'textAnalysis',
    position: [0, 9],
    size: [3, 2],
    indicator: 'SENTIMENT',
    criticalMomentInclude: [11652],
    extraConfigs: {
      hideBars: true
    }
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'wordCountWithLinks',
    title: 'Nube de Palabras / Frecuencia en comentarios',
    position: [2, 9],
    size: [4, 2],
    mapper: haWordCountWithLinksMapper,
    criticalMomentInclude: [11652]
  }),


]

export default {
  title: 'Hospital Angeles Laboratorios Médicos Solicitantes',
  description: 'Hospital Angeles Laboratorios Médicos Solicitantes',
  widgets: (filters: FiltersUI) => {
    return allWidgets
  },
} as DasboardConfig
