import { getCommonWidget } from '../../dashWidgetHelper'
import { HA_QUARTER_COLORS, commonWidgets, 
  haSingleLineDetailedByGeoMapper, haWordCountWithLinksMapper } from './../commonWidgetsHa'


const allWidgets = [
  /**
   * NPS
   */
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haSingleLineDetailedByMonthAndGeo',
    title: 'NPS General por mes',
    size: [4, 4],
    extraConfigs: {
      backgroundColor: 'transparent',
      theme: 'dark',
    },
    // @ts-ignore
    mapper: haSingleLineDetailedByGeoMapper,
    indicator: 'NPS_GENERAL',
    key: 'nps-line-detailed-single',
  }),
  /**
   * Satisfacción
   */
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haSingleLineDetailedByMonthAndGeo',
    title: 'Satisfacción General por mes',
    size: [4, 4],
    extraConfigs: {
      backgroundColor: '#3a1b52',
      theme: 'dark',
    },
    // @ts-ignore
    mapper: haSingleLineDetailedByGeoMapper,
    indicator: 'CSAT_GENERAL',
    key: 'csat-line-detailed-single',
  }),
  {
    title: 'Matriz de Priorización',
    type: 'label',
    size: [4, 1]
  },
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'quarterTrend',
    extraConfigs: {
      colors: HA_QUARTER_COLORS,
      headersX:['Malo', 'Regular', 'Bueno']
    },
    size: [4, 3],
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haSentimentPieNPS',
    title: 'Análisis de sentimientos',
    size: [2, 2],
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'wordCountWithLinks',
    title: 'Nube de Palabras / Frecuencia en comentarios',
    size: [2, 2],
    mapper: haWordCountWithLinksMapper,
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'textAnalysis',
    size: [4, 3],
    indicator: 'SENTIMENT'
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'diagramaIshikawa',
    title: 'Diagrama Ishikawa',
    size: [4, 3],
    indicator: 'SENTIMENT'
  }),

]

export default {
  title: 'Default Dashboard',
  description: 'Dashboard de perfil Default',
  widgets: (filters: FiltersUI) => allWidgets,
} as DasboardConfig
