import { DEFAULT_COLORS, DEFAULT_NPS_LABELS } from '@components/widgets/apacheEchart/Commons'
import { getCommonWidget } from '../../dashWidgetHelper'
import { commonWidgets } from './../commonWidgetsHa'


const SENTIMENTS_LABELS = {
  '1': 'Positivo',
  '-1': 'Negativo',
  '0': 'Neutral'
}

const sentimentMapper = (data: any): any => {
  //console.log('Análisis de sentimientos por dia formattedDate')
  //console.log(data)
  const values = data !== undefined
    ? data
      .map((item: any) => ({...item, weight: item.groupId === 0 ? -2 : item.group}))
      // @ts-ignore
      .asMutable()
      .sort((item1: any, item2: any) => item2.weight - item1.weight)
      .map((item: any) => {
        const date = item?.['group_0']
        const group = item?.['group']
        const count = item?.count
        const value = item?.value
        return {
          category: date, // date
          group: group,
          groupName: SENTIMENTS_LABELS[group],
          id: item?.group + '-' + group,
          label: SENTIMENTS_LABELS[group],
          value: (value * 100) / count
        }
      })
    : {}
  //console.log(values)
  return values
}

const surveyResponsesCounterMapper = (data: any): any => {
  const values = data.map((item: any) => {
    return {
      category: item?.['group_1'], // date
      //'group_0': channelKey,
      group: item?.['group_0'],
      groupName: item?.['group_0'],
      //'group_1': dateKey,
      id: item?.['groupId_0'],
      label: item?.['group_0'],
      value: item?.value?.externalSourceCount
    }
  })

  //console.log(values)
  return values
}

export default {
  title: 'Social Listening',
  description: 'Social Listening',
  backgroundClassName: (filters) => 'epsilon-hero-bg',
  widgets: (filters: FiltersUI) => [
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'textAnalysis',
      key: 'text-analysis-sl',
      position: [0, 0],
      size: [4, 4],
      indicator: 'SENTIMENT',
      extras: {
        status: 'EXTERNAL_SOURCE',
        criticalMoments: undefined,
        channelInclude: [7]
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByTagBest',
      title: 'Sentimiento positivo con más frecuencia',
      position: [0, 4],
      size: [2, 1],
      indicator: 'SENTIMENT',
      extras: {
        trunc: 'HALF_DOWN',
        topSize: 3,
        status: 'EXTERNAL_SOURCE',
        criticalMoments: undefined,
        channelInclude: [7]
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByTagWorst',
      title: 'Sentimiento negativo con más frecuencia',
      position: [2, 4],
      size: [2, 1],
      indicator: 'SENTIMENT',
      extras: {
        trunc: 'HALF_UP',
        bottomSize: 3,
        status: 'EXTERNAL_SOURCE',
        criticalMoments: undefined,
        channelInclude: [7]
      },
    }),
    {
      title: 'Indicadores generales',
      helpLink: '--',
      position: [0, 5],
      size: [4, 2],
      config: {
        id: 'general-indicators',
        type: 'dataTable',
        color: ['#CCC'],
        enableArcLabels: true,
        valueFormat: ' >-.0%',
        groupName: 'Campaña',
        mapper: (data: any): any => {
          return data.map((item: any) => {
            return [
              'Sentimiento Positivo',
              'Sentimiento Negativo',
              'Promedio Likes'
            ].map((ind: string) => ({
              groupName: ind,
              'group_0': ind,
              'group_1': item['Hospital'],
              value: item[ind]
            }))
          }).flat(1)
        },
        indicators: [
          {
            indicator: 'NPS_GENERAL',
            url: 'reports/indicators-social',
            label: 'group',
            value: 'value',
            singleton: true,
            keyExtract: [
              'Hospital',
              'Sentimiento Positivo',
              'Sentimiento Negativo',
              'Promedio Likes'
            ],
            extras: {
              resultType: 'JSON',
              status: 'EXTERNAL_SOURCE',
              channelInclude: [7]
            }
          }
        ]
      }
    },
    {
      title: 'Redes sociales',
      helpLink: '--',
      position: [0, 7],
      size: [2, 2],
      config: {
        id: 'social-media-by-channel',
        type: 'pie',
        decimals: 2,
        mapper: (data: any): any => {
          const total = data.reduce((acc: any, curr: any) => {
            return acc + curr?.value?.externalSourceCount
          }, 0)
          const values = data.map((item: any) => {
            const count = item?.value?.externalSourceCount
            return {
              value: total > 0 ? (count * 100 / total) : 0,
              label: item?.['group_0'],
              count: count,
              id: item?.['groupId_0']
            }
          })
          //console.log(values)
          return values
        },
        indicators: [
          {
            label: 'group',
            value: 'value',
            singleton: true,
            keyExtract: ['group_0', 'groupId_0', 'value'],
            grouped: 'channel',
            indicator: 'SURVEY_RESPONSE_COUNTERS',
            extras: {
              status: 'EXTERNAL_SOURCE',
              criticalMoments: undefined
            }
          }
        ]
      }
    },
    {
      title: 'Análisis de sentimientos',
      helpLink: '--',
      position: [2, 7],
      size: [2, 2],
      config: {
        id: 'sentiment-pie',
        type: 'pie',
        decimals: 2,
        center: ['30%', '50%'],
        colors: DEFAULT_COLORS,
        mapper: (data: any): any => {
          //console.log('Analisis de sentimientos mapper')
          //console.log(data[0])

          const values = ['promoters', 'detractors', 'passives']
            .map((item: string) => ({
              value: data[0]?.[item],
              label: DEFAULT_NPS_LABELS[item],
              id: item
            }))
          //console.log(values)
          return values
        },
        indicators: [
          {
            label: 'group',
            value: 'value',
            singleton: true,
            keyExtract: ['detractors', 'detractorCount',
              'passives', 'passiveCount',
              'promoters', 'promoterCount'],
            indicator: 'SENTIMENT',
            extras: {
              status: 'EXTERNAL_SOURCE',
              criticalMoments: undefined,
              formula: 'nps',
              formulaConfig: 'nps-sentiment'
            }
          }
        ]
      }
    },
    {
      title: 'Menciones en redes sociales por día',
      helpLink: '--',
      position: [0, 9],
      size: [2, 2],
      config: {
        id: 'mentions-by-week-line',
        type: 'line',
        decimals: 2,
        legendItemWidth: 10,
        mapper: surveyResponsesCounterMapper,
        indicators: [
          {
            label: 'group',
            value: 'value',
            singleton: true,
            keyExtract: ['group_0', 'group_1', 'value', 'groupId_0', 'label', 'id'],
            grouped: 'channel,formattedDate',
            indicator: 'SURVEY_RESPONSE_COUNTERS',
            sort: { sortBy: 'group_1', value: 'desc', },
            extras: {
              status: 'EXTERNAL_SOURCE',
              criticalMoments: undefined,
              periods: 10
            }
          }
        ]
      }
    },
    {
      title: 'Menciones en redes sociales por semana',
      helpLink: '--',
      position: [2, 9],
      size: [2, 2],
      config: {
        id: 'mentions-by-month-line',
        type: 'line',
        decimals: 2,
        legendItemWidth: 10,
        mapper: surveyResponsesCounterMapper,
        indicators: [
          {
            label: 'group',
            value: 'value',
            singleton: true,
            keyExtract: ['group_0', 'group_1', 'value', 'groupId_0', 'label', 'id'],
            grouped: 'channel,formattedWeek',
            indicator: 'SURVEY_RESPONSE_COUNTERS',
            sort: { sortBy: 'group_1', value: 'desc', },
            extras: {
              status: 'EXTERNAL_SOURCE',
              criticalMoments: undefined,
              periods: 6
            }
          }
        ]
      }
    },
    {
      title: 'Análisis de sentimientos por dia',
      helpLink: '--',
      position: [0, 11],
      size: [2, 2],
      config: {
        id: 'sentiments-by-week-line',
        type: 'line',
        decimals: 2,
        colors: DEFAULT_COLORS,
        legendItemWidth: 10,
        mapper: sentimentMapper,
        indicators: [
          {
            label: 'group',
            value: 'value',
            singleton: true,
            keyExtract: [],
            grouped: 'formattedDate,numberAnswer',
            indicator: 'ANALYTICS_SENTIMENT',
            //grouped: 'formattedDate',
            //indicator: 'SENTIMENT',
            extras: {
              status: 'EXTERNAL_SOURCE',
              criticalMoments: undefined,
              periods: 10
            }
          }
        ]
      }
    },
    {
      title: 'Análisis de sentimientos por semana',
      helpLink: '--',
      position: [2, 11],
      size: [2, 2],
      config: {
        id: 'sentiments-by-month-line',
        type: 'line',
        decimals: 2,
        colors: DEFAULT_COLORS,
        mapper: sentimentMapper,
        indicators: [
          {
            label: 'group',
            value: 'value',
            singleton: true,
            keyExtract: [],
            grouped: 'formattedWeek,numberAnswer',
            indicator: 'ANALYTICS_SENTIMENT',
            extras: {
              status: 'EXTERNAL_SOURCE',
              criticalMoments: undefined,
              periods: 6
            }
          }
        ]
      }
    },
    {
      title: 'Nube de palabras',
      position: [0, 13],
      size: [4, 4],
      config: {
        id: 'word-count-social-listening',
        type: 'wordCloud',
        indicators: [
          {
            indicator: 'WORD_COUNT',
            keyExtract: ['count', 'value', 'group'],
            singleton: true,
            extras: {
              status: 'EXTERNAL_SOURCE'
            }
          }
        ]
      }
    }
  ],
} as DasboardConfig
