import { getCommonWidget } from '../../dashWidgetHelper'
import {
  HA_QUARTER_COLORS,
  commonWidgets,
  haWordCountWithLinksMapper
} from './../commonWidgetsHa'


export default {
  title: 'Default Dashboard',
  description: 'Dashboard de perfil Default',
  widgets: (filters: FiltersUI) => [
    {
      title: 'Datos generales',
      type: 'label',
      size: [4, 1]
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'haSentimentPieNPS',
      title: 'Análisis de sentimientos',
      size: [2, 2],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'wordCountWithLinks',
      title: 'Nube de Palabras / Frecuencia en comentarios',
      size: [2, 2],
      mapper: haWordCountWithLinksMapper,
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'textAnalysis',
      size: [4, 3],
      indicator: 'SENTIMENT'
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'diagramaIshikawa',
      title: 'Diagrama Ishikawa',
      size: [4, 3],
      indicator: 'SENTIMENT'
    }),
  
  ],
} as DasboardConfig
