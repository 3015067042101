import { getCommonWidget } from "../../dashWidgetHelper";
import {
    PRODUTURO_SCALE_5_COLORS, PRODUTURO_PIE_COLORS, PRODUTURO_PIE_SINO_COLORS,
    commonWidgets, getProfuturoNpsAcumulado, getProfuturoNpsPorMes, getProfuturoNpsAcumuladoByBimonthly, getExtrasFechas, getMapperBimestralHistorico
} from "../commonWidgetsProfuturo";



export default {
    title: 'Acompañamiento Dashboard',
    description: 'Acompañamiento Dashboard',
    backgroundClassName: (filters: FiltersUI) => 'epsilon-hero-bg',
    widgets: (filters: FiltersUI) => [
        {
            title: 'Principales Indicadores',
            size: [4, 1],
            type: 'label',
            config: { color: '#B7AADC' },
        },
        getProfuturoNpsAcumuladoByBimonthly({
            size: [1, 2],
            indicator: 'cuest_afore_v_ll_pregunta_2',
            extras: getExtrasFechas

        }),
        //no hay pregunta que agrupe por bimenstre como en 173003100 (cutrimestre), debemos crear la pregunta y los registros de las mismas?
        getProfuturoNpsPorMes({
            helpText: 'A partir de tu experiencia, en una escala del 0 al 10, dónde 0 es nada probable y 10 muy probable, ¿qué tan probable es que nos recomiendes con un amigo o familiar?',
            size: [1, 2],
            indicator: 'cuest_afore_v_ll_pregunta_2',
            extras: getExtrasFechas,
            historicGrouped: 'formattedBimonthlyPeriod',
            historicLabel: 'group',
            historicExtras: getExtrasFechas,
            historicMapper: getMapperBimestralHistorico

        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'wordCount',
            title: 'Contacto esperado',
            size: [2, 2],
            helpText: '¿Qué tipo de contacto esperas recibir de Profuturo?',
            indicator: 'cuest_afore_v_ll_pregunta_31',
            extras: getExtrasFechas,
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'pieGenericMedium',
            title: 'Razón clave para ser cliente de Profuturo',
            size: [3, 2],
            helpText: '¿Cuál es el principal motivo por el que estás con Profuturo?',
            indicator: 'cuest_afore_v_ll_pregunta_9',
            extraConfigs: {
                colors: PRODUTURO_PIE_COLORS,
            },
            extras: getExtrasFechas,
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'pieGenericMedium',
            title: 'Medio de preferencia',
            size: [1, 2],
            helpText: '¿Por cuál medio te gustaría recibir información sobre tu Cuenta Individual?',
            indicator: 'cuest_afore_v_ll_pregunta_78',
            extraConfigs: {
                colors: PRODUTURO_PIE_COLORS,
            },
            extras: getExtrasFechas,
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'pieGenericMedium',
            title: 'Recomendacíon',
            size: [2, 2],
            helpText: 'En el último año, ¿recomendaste a Profuturo a otras personas?',
            indicator: 'cuest_afore_v_ll_pregunta_18',
            extraConfigs: {
                colors: PRODUTURO_PIE_SINO_COLORS,
            },
            extras: getExtrasFechas,
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'pieGenericMedium',
            title: 'Cantidad de personas recomendadas',
            size: [2, 2],
            helpText: '¿A cuántas personas le recomendaste pertenecer a Profuturo?',
            indicator: 'cuest_afore_v_ll_pregunta_19',
            extraConfigs: {
                colors: PRODUTURO_PIE_COLORS,
            },
            extras: getExtrasFechas,
        }),

        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'pieGenericMedium',
            title: 'Lealtad',
            size: [1, 2],
            helpText: 'Si tu experiencia con Profuturo se mantiene igual que hasta ahora, ¿estarías dispuesto a seguir siendo nuestro cliente?',
            indicator: 'cuest_afore_v_ll_pregunta_76',
            extraConfigs: {
                colors: PRODUTURO_PIE_SINO_COLORS,
            },
            extras: getExtrasFechas,
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'pieGenericMedium',
            title: 'Desistimiento',
            size: [3, 2],
            helpText: '¿Por qué razón no continuarías en Profuturo?',
            indicator: 'cuest_afore_v_ll_pregunta_20',
            extraConfigs: {
                colors: PRODUTURO_PIE_COLORS,
            },
            extras: getExtrasFechas,
        }),

        {
            title: 'Atributos en la experiencia de Grado de SATISFACION',
            size: [4, 1],
            type: 'label',
            config: { color: '#B7AADC' },
        },
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'stackedBar',
            title: 'La facilidad para comunicarse con Profuturo',
            size: [4, 1],
            helpText: '¿Qué tan satisfecho estás con cada uno de los siguientes aspectos?',
            indicator: 'cuest_afore_v_ll_pregunta_11',
            extraConfigs: {
                colors: PRODUTURO_SCALE_5_COLORS
            },
            extras: getExtrasFechas,
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'stackedBar',
            title: 'La rapidez y facilidad en los procesos / trámites',
            size: [4, 1],
            helpText: '¿Qué tan satisfecho estás con cada uno de los siguientes aspectos?',
            indicator: 'cuest_afore_v_ll_pregunta_12',
            extraConfigs: {
                colors: PRODUTURO_SCALE_5_COLORS
            },
            extras: getExtrasFechas,
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'stackedBar',
            title: 'La buena atención por parte del personal',
            size: [4, 1],
            helpText: '¿Qué tan satisfecho estás con cada uno de los siguientes aspectos?',
            indicator: 'cuest_afore_v_ll_pregunta_13',
            extraConfigs: {
                colors: PRODUTURO_SCALE_5_COLORS
            },
            extras: getExtrasFechas,
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'stackedBar',
            title: 'La recepción de mi Estado de Cuenta de manera puntual (cada 4 meses)',
            size: [4, 1],
            helpText: '¿Qué tan satisfecho estás con cada uno de los siguientes aspectos?',
            indicator: 'cuest_afore_v_ll_pregunta_14',
            extraConfigs: {
                colors: PRODUTURO_SCALE_5_COLORS
            },
            extras: getExtrasFechas,
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'stackedBar',
            title: 'El cumplimiento con lo que me ofrecieron al momento de la contratación',
            size: [4, 1],
            helpText: '¿Qué tan satisfecho estás con cada uno de los siguientes aspectos?',
            indicator: 'cuest_afore_v_ll_pregunta_15',
            extraConfigs: {
                colors: PRODUTURO_SCALE_5_COLORS
            },
            extras: getExtrasFechas,
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'stackedBar',
            title: 'La asesoría y educación financiera recibida para planear mi futuro.',
            size: [4, 1],
            helpText: '¿Qué tan satisfecho estás con cada uno de los siguientes aspectos?',
            indicator: 'cuest_afore_v_ll_pregunta_16',
            extraConfigs: {
                colors: PRODUTURO_SCALE_5_COLORS
            },
            extras: getExtrasFechas,
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'stackedBar',
            title: 'La frecuencia con la que me contactan para informarme sobre mi Cuenta Individual',
            size: [4, 1],
            helpText: '¿Qué tan satisfecho estás con cada uno de los siguientes aspectos?',
            indicator: 'cuest_afore_v_ll_pregunta_17',
            extraConfigs: {
                colors: PRODUTURO_SCALE_5_COLORS
            },
            extras: getExtrasFechas,
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'textAnalysis',
            title: 'Análisis de Sentimientos',
            position: [0, 3],
            size: [4, 4],
            indicator: 'SENTIMENT',
            extras: getExtrasFechas,
        }),

    ]
} as DasboardConfig