import { getHaLineDetailedByMonthAndGeo } from '../widgetsFunctionsHa'


export default {
  title: 'Laboratorios Dashboard',
  description: 'Dashboard de perfil Laboratorios',
  widgets: (filters: FiltersUI, filtersData: Record<string, any>) => [
    {
      title: 'Laboratorios',
      type: 'label',
      size: [4, 1]
    },
    /**
   * NPS
   */
    getHaLineDetailedByMonthAndGeo({
      key: `nps-line`,
      indicator: 'NPS_LABORATORIOS',
      title: 'NPS General por mes laboratorios',
      size: [4, 4],
      formula: 'nps-10',
      extraConfigs: {
        backgroundColor: '#3a1b52',
        theme: 'dark',
      },
      criticals: [
        { key: 'lms', criticalMoments: [11652], label: 'Médicos Solicitantes' },
        { key: 'lss', criticalMoments: [11656], label: 'Servicios Subrogados' }
      ]
    }),
    /**
     * Satisfacción
     */
    getHaLineDetailedByMonthAndGeo({
      key: `csat-line`,
      indicator: 'CSAT_LABORATORIOS',
      title: 'Satisfacción General por mes laboratorios',
      size: [4, 4],
      formula: 'nps-5',
      extraConfigs: {
        backgroundColor: '#3a1b52',
        theme: 'dark',
      },
      criticals: [
        { key: 'lms', criticalMoments: [11652], label: 'Médicos Solicitantes' },
        { key: 'lss', criticalMoments: [11656], label: 'Servicios Subrogados' }
      ]
    })
  ],
} as DasboardConfig
